.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.testGradient {
  background-color: "red";
  background-image: linear-gradient(to bottom right, red, yellow);
}

.cuadroRedondeado {
  background-color: #aaa;
  border-radius: 8px;
  border-width: 1px;
}

@media (max-width: 899px) {
  .navLogo {
    display: none;
  }
}

.link {
  text-decoration: none;
  color: #fff;
}

.link_subitem {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.54);
}

.link_navbar {
  color: #80baf4;
  text-decoration: none;
}

.btn404 {
  background: #1976d2 !important;
  color: #fff !important;
  font-size: 15px !important;
}

.btn404Outlined {
  border: 1px solid #1976d2 !important;
  font-size: 15px !important;
  color: #1976d2 !important;
}

#apellidos_nombres {
  text-transform: uppercase !important;
}

#tratamiento {
  text-transform: uppercase !important;
}

#contacto {
  text-transform: uppercase !important;
}

#fk_ven_tratamiento {
  text-transform: uppercase !important;
}

/* ====tablas== */
.tableSticky {
  background-color: #2b2b !important;
}

.linkDefault{
  color:antiquewhite;
  text-decoration: none;
}
.linkDefault:link {text-decoration:none; background-color:transparent;  color:antiquewhite;}
.linkDefault:visited{text-decoration:none;background-color:transparent; color:antiquewhite;}
.linkDefault:active {text-decoration:none;background-color:transparent; color:antiquewhite;};
.linkDefault:hover {text-decoration:none;background-color:transparent; color:antiquewhite;};
.linkDefaultDrawer{text-decoration:none; color: #424242};
.linkDefaultDrawer:link{text-decoration:none; color: #424242};
.linkDefaultDrawer:visited{text-decoration:none; color: #424242};
.linkDefaultDrawer:active{text-decoration:none; color: #424242};

/* ======PRELOADER===== */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #66bb6a;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #43a047;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #2e7d32;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/* ====otro===== */
.loaderBody {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.loader {
  position: relative;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 2px;
  width: 100px;
  height: 100px;
}

.loader>div {
  position: relative;
  width: 100%;
  height: 100%;
  background: #43a047;
  transform: scale(0);
  transform-origin: center center;
  animation: loader 2s infinite linear;
}

.loader>div:nth-of-type(1),
.loader>div:nth-of-type(5),
.loader>div:nth-of-type(9) {
  animation-delay: 0.4s;
}

.loader>div:nth-of-type(4),
.loader>div:nth-of-type(8) {
  animation-delay: 0.2s;
}

.loader>div:nth-of-type(2),
.loader>div:nth-of-type(6) {
  animation-delay: 0.6s;
}

.loader>div:nth-of-type(3) {
  animation-delay: 0.8s;
}

@keyframes loader {
  0% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }

  80% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

/* =====/FIN Preloader========= */