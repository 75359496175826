.logBox{
    padding: 20px;
    height: 70vh;
    top:15vh;
    max-width: 90vh;
    min-width: 300px;
    width: 360px;
    margin: 20px auto;
    min-height: 440px;
}

.logBoxContent{
    align-items: center;
    align-content: center;
    text-align: center;
}

.logComponent{
    margin: 10px;
}

.alertMessage{
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 3px;
    color: #A3393B;    
    font-size: 14pt;
}