.navLogo{
    object-fit: scale-down;
    max-width: 50px;
    max-height: 50px;
    margin-right: 10px;
}
.PerTableContainer{
    padding-top: 15px;
}
.buttonTable{
    width: 180px;
    max-width: 180px;
}
.groupcomprobante{
    border: 1px solid #1976D2;
    border-radius: 5px;
}
.menulink{
    text-decoration: none;
}